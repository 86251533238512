import React from 'react'
import ColorPageView from '../components/ColorPageView'

const Gold = () => {
  const text = [
    `Gold—beautiful, valuable gold—what would you do if you found a treasure chest full of gold? Wouldn’t that be great? Would you make beautiful gold jewelry to wear? Would you sell the gold for millions of dollars? Here on earth, gold is pretty important, but the Bible tells us that in heaven, the street of the city is pure gold. Can you imagine that?`,
    `Heaven sure is a wonderful place! Not only is the street made of gold, but we also know that there is no crying, no pain, and no death in heaven. Maybe you know some more great things about heaven. Do you know what the greatest thing about heaven is? The greatest thing about heaven is that God is there. That’s right! Just like you and I each have a home, God’s home is in heaven! Just imagine seeing and spending forever with our loving God who created you and me and this whole world. I can’t wait! Can you? One more thing about heaven. God is so perfect and holy that nothing bad can go into heaven to be with God.`
  ]

  return (
    <ColorPageView
        // backgroundColor={"linear-gradient(to bottom, #fefcea 0%,#f1da36 100%)"}
        backgroundColor={`center url("images/gold.jpg")`}
        listenSrc="audio/gold_page.wav"
        readParagraphs={text}
        prevPage={"/"}
        nextPage={"/black"}
    ></ColorPageView>
  )
}

export default Gold