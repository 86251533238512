import React from 'react'
import ColorPageView from '../components/ColorPageView'

const White = () => {
  const text = [
    `“Whiter than snow.” Whiter than snow? How can we have a black heart, wash in red blood, and end up with a white heart? I admit, it sounds pretty strange, but that is what the Bible says in Revelation 7, “They have washed their robes and made them white in the blood of the Lamb.”`,
    `King David—you remember, the same guy who as a boy used his slingshot to kill the giant Goliath? This same David who knew about God’s love and power said, “Wash me and I will be whiter than snow.” David knew that if God forgave his sins, he would be completely forgiven.`,
    `On the red page, we said that because Jesus loves us, He died on the cross for us. Oh! It’s wonderful to have someone love us that much! But Jesus didn’t stay dead. The Bible tells us that He rose from the grave. He resurrected. That is, He came back to life! He was stronger than death, and He won! Jesus saved us from death. He saved us from our sins.`,
    `Always remember that the death and resurrection of Jesus is the main truth of the gospel.`,
    `Did you know that the word “gospel” means “good news”? When we believe who Jesus is, we put our trust in Jesus. We share in the victory He won, and we are winners, too! Now that’s great news!`,
    `I remember the first day I put my trust in Jesus. I first realized that I was a sinner with a dark heart before God. It made me feel terrible, but I told God that I was sorry and that I believed who Jesus was. I knew that He died on the cross for me. I knew that His blood washed my sins away. How about that? My sins were forgiven and forgotten. God could see me just as if I had never sinned. I wanted to belong to God, so now, I could have a special gift. I have a new life—a life that lasts forever! That was the best thing that ever happened to me! How about you? `,
    `In 2 Corinthians, the Bible tells us that God made Jesus, who had no sin, to be sin for us, so that in Jesus, we might become the righteousness of God. That’s right. Even though all of us have sinful hearts, God changes us to be like Him. Philippians 1:6 says that God who began a good work in you is faithful to complete it. Did you hear that? A guarantee right from God! And since God guarantees His work in us, He sees it as already done.`,
    `This great Bible truth is called being “justified.” That’s a big word. A good way to remember it is to break “justified” into “just-as-if-I’d” never sinned. If we had never sinned, we could live with God forever. All of us have black sinful hearts, but thanks to Jesus’s blood, we sinful people who believe in Jesus can be “just-as-if-I’d” never sinned and be assured a life with God that starts here on earth and continues forever in heaven. I remember the day I first put my trust in Jesus. To know that my sins were forgiven, that I was justified, and now have a new life in God was the best thing that ever happened to me! How about you?`,
  ]

  return (
    <ColorPageView
        backgroundColor={'#fff'}
        listenSrc="audio/white_page.wav"
        readParagraphs={text}
        prevPage={'/red'}
        nextPage={'/green'}
    ></ColorPageView>
  )
}

export default White