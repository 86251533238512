import React, { useState } from 'react'
import commonStyles from '../styles/CommonStyles.module.css'
import styles from '../styles/ColorPage.module.css'
import { Link } from 'react-router-dom'

const ColorPageView = ({listenSrc, readParagraphs, backgroundColor, nextPage, prevPage, textColor = '#000'}) => {
  const [showListen, setShowListen] = useState(false);
  const [showText, setShowText] = useState(false);

  return (
    <div 
        style={{background: backgroundColor}} 
        className={commonStyles.container} >
        <div className={styles.wrapper}>
            <div className={commonStyles.wrapperRow}>
                <div className={commonStyles.wrapperRowSep}>
                    <Link 
                        className={styles.prevButton}
                        to={prevPage}>
                        <img src="images/right-chevron.png"></img>
                    </Link>
                    <button 
                    className={commonStyles.standardButton} 
                    onClick={() => setShowListen(true)}>
                        Listen
                    </button>
                </div>
                <div className={commonStyles.wrapperRowSep}>
                    <button 
                    className={commonStyles.standardButton} 
                    onClick={() => setShowText(true)}>
                        Read
                    </button>
                    <Link 
                        className={styles.nextButton}
                        to={nextPage}>
                        <img src="images/right-chevron.png"></img>
                    </Link>
                </div>
            </div>
            {showListen &&
            <div className={styles.pageAudio}>
                <audio controls autoPlay>
                    <source src={listenSrc} type="audio/mpeg"/>
                </audio>
            </div>}
            {showText &&
            <div style={{color: textColor}} className={styles.pageText}>
                {readParagraphs.map(element => <h3> {element} </h3>)}
            </div>}
        </div>
    </div>
  )
}

export default ColorPageView