import React from 'react'
import ColorPageView from '../components/ColorPageView'

// green page
const Green = () => {
  const text = [
    `I love springtime, don’t you? Flowers appear everywhere. Leaves come out on the trees. Green grass starts growing again. This green page reminds me of spring! Did you know that just like grass and trees and animals and even humans all grow physically, in a similar way, we also grow spiritually. That’s right! When we receive God’s gift of a new life, we start growing and keep on growing until we are perfect in heaven. God is at work in us making us more and more like Jesus Christ. The Bible says, in Ephesians 2:10, that we are God’s workmanship. Just imagine a famous artist painting finishing touches on a masterpiece. Well, we are God’s masterpiece. Isn’t that exciting?`,
    `Some of you might ask, “How do we grow spiritually? How do we become more and more like Jesus?” I can answer that in just one word—love. What a great word! All of you have family or friends who love you. How does that make you feel? Do you remember the red page? Jesus loves us so very much! As we get to know our God better by praying to Him and reading His word, the Bible, we will learn more and more about how much He loves us and what He wants us to do. In John 14:15, the Bible tells us if we love Jesus, we will obey what He commands. As we obey God, we will find ourselves loving and serving other people just like Jesus did. Another great way we grow is when we spend time together with other believers, or Christians. As we worship God and learn together what God wants for us, we can encourage each other.`,
    `I must warn you that growing spiritually will not always be easy. The devil is God’s enemy. When we belong to God, we’ve joined God’s team, and the devil also becomes our enemy. He tries to stop us from growing. Also, we live in a sinful world full of temptations to go against God. Worst of all, we have a sinful heart inside of us that wants us to be selfish instead of loving God and other people just as Jesus did. There might come times, even as a Christian, when you sin against God, and it seems like you are not growing at all. Don’t get discouraged. Remember, God guarantees that you will grow more and more like Jesus. If you do sin, 1 John 1:9 lets us know that as we confess our sin, God forgives our sin and gets us back on track.`,
    `Whatever happens—don’t worry. God promises never to leave you. He won’t let anything ruin His masterpiece! God is making you more and more like Jesus, and one day you will be perfect in heaven with Jesus forever.`
  ]

  return (
    <ColorPageView
        backgroundColor={"#7CFC00"}
        listenSrc="audio/green_page.wav"
        readParagraphs={text}
        nextPage={"/"}
        prevPage={"/white"}
    ></ColorPageView>
  )
}

export default Green