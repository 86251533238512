import React from 'react'
import commonStyles from '../styles/CommonStyles.module.css'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div className={commonStyles.container}>
        <div className={commonStyles.wrapper}>
            <h1>WORDLESS BOOK</h1>
            <Link className={commonStyles.standardButton} to="/gold">Start Book</Link>
        </div>
    </div>
  )
}

export default Home