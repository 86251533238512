import React from 'react'
import ColorPageView from '../components/ColorPageView'

const Black = () => {
  const text = [
    `Have you ever been in a room that was so dark that you couldn’t see anything—not even your own hand? It was pretty scary, wasn’t it? The Bible teaches that because of sin our hearts are just as dark as that room. Do you know what sin is? Sin is anything we do, say, or think that is against God. Can you think of some sins? How about lying, cheating, stealing, hurting others—all these are examples of sin. Maybe you can think of some other sins that I have not mentioned.`,
    `Can you think of anyone who has sinned? Yes, that’s right. The devil is full of sin, but he’s not the only one. In Romans 3:23, the Bible tells us that all have sinned and fall short of the glory of God. The important thing is—have you sinned? If you are honest, you can probably think of some time that you sinned against God. You remember what the Bible said, “All have sinned.” Even though I don’t know your name, because of what the Bible says, I know that I have sinned, you have sinned, and every person we know has sinned.`, 
    `That’s not all. In the book of Romans, the Bible lets us know that our natural minds and hearts are sinful and totally against God. That means that our minds and hearts naturally are spiritually and completely dark. Let me explain it like this. When I was a child, no one had to teach me how to disobey my parents. How about you? Did your mom or dad ever tell you, “Now, be sure you jump up and down on the bed, and go hit your brother in the head. Oh, and above all else, I want you to throw your food on the floor, stomp, and yell, and swing from the door.” This is pretty silly, isn’t it? For the most part, I have always wanted to do things my way, and many times, obeying my parents was not easy. Even though I knew I would be punished when I disobeyed, I still wanted my way. So, you see, we don’t have to learn to sin. It comes naturally.`,
    `Sin is terrible! It hurts us. It hurts others. Worst of all, it hurts God. Do you remember the gold page? God, who is perfect, cannot allow any sin in heaven. Since we have sinned, we will never be allowed to go to heaven. We are separated from God; and instead, we will be forever tortured in a place called Hell. This is death. Death is our punishment for being sinful and disobedient to God. But God loves us more than we can imagine, and being separated from us makes Him very sad. He wants us to be with Him in heaven.`,
  ]

  return (
    <ColorPageView
        backgroundColor={"#000"}
        listenSrc="audio/black_page.wav"
        readParagraphs={text}
        textColor='#fff'
        prevPage={"/gold"}
        nextPage={"/red"}
    ></ColorPageView>
  )
}

export default Black