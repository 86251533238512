import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Gold from '../pages/Gold';
import White from '../pages/White';
import Black from '../pages/Black';
import Red from '../pages/Red';
import Green from '../pages/Green';

class Main extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Routes>
                    <Route key={1} path='/' element={
                        <Home/>
                    } />
                    <Route key={2} path='/gold' element={
                        <Gold/>
                    } />
                    <Route key={3} path='/white' element={
                        <White/>
                    } />
                    <Route key={4} path='/red' element={
                        <Red/>
                    } />
                    <Route key={5} path='/black' element={
                        <Black/>
                    } />
                    <Route key={6} path='/green' element={
                        <Green/>
                    } />
                    <Route key={7} path='*' element={
                        <Home/>
                    } />
                </Routes>
            </>
        )
    }
}


export default Main