import React from 'react'
import ColorPageView from '../components/ColorPageView'

const Red = () => {
  const text = [
    `Blood—not usually a pleasant thought. Do you remember the last time you were bleeding? It probably hurt! Most people don’t even like to talk about blood. However, this red page reminds me of the blood of Jesus Christ, and that’s a different story. You see, when Jesus Christ died on the cross, He was getting the punishment you and I were supposed to get—death. In Hebrews 4:15, the Bible tells us that Jesus never sinned. He didn’t deserve to be punished, but Jesus died for us. What an amazing love God has for us!`,
    `Some of you have probably heard what the Bible tells us in John 3:16, ‘‘For God so loved the world [that includes you], that He gave His only begotten Son [this is who we call Jesus Christ], that whoever believes in Him, [whoever believes that Jesus died for us], should not perish [that means they don’t die] but have eternal life.’’ There you have it. Believers go to heaven. God loves us so much!`,
    `The next time you see a bright red rose or a Valentine’s card, remember that the greatest love in the whole world is when Jesus died for you and me. He saved us from our punishment. He saved us from our sins. He gave His blood for us, and we can say that His blood has washed the sin from our dark hearts. Those who believe in Jesus have their hearts washed in the blood of Jesus, and now they are whiter than snow.`
  ]

  return (
    <ColorPageView
        backgroundColor={"radial-gradient(rgb(253, 44, 56) 3.3%, rgb(176, 2, 12) 98.4%)"}
        listenSrc="audio/red_page.wav"
        readParagraphs={text}
        textColor='#fff'
        nextPage={"/white"}
        prevPage={"/black"}
    ></ColorPageView>
  )
}

export default Red